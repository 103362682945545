import React from "react";
import classnames from "classnames";
import { Link } from "gatsby";

import style from "./Header.module.scss";

import logo from "../../../assets/images/logo.svg";
import logoUE from "../../../assets/images/logo-ue1.svg";

function Header(): JSX.Element {
  return (
    <header className={classnames("d-flex flex-column justify-content-center", style.header)}>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className={style.headerContent}>
              <Link to="/" className={classnames("d-flex flex-column justify-content-center", style.headerLogo)}>
                <img src={logo} alt="Stream sage" className={classnames(style.logo)} />
              </Link>
              <div className="d-flex flex-column justify-content-center">
                <img src={logoUE} alt="Unia Europejska" className={classnames(style.ueLogo)} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
