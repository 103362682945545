import React from "react";
import classnames from "classnames";

import style from "./Section.module.scss";

type SectionThemes = "light" | "none";

interface SectionProps {
  children: React.ReactNode;
  containerClassName?: string;
  sectionClassName?: string;
  rowClassName?: string;
  fullWidth?: boolean;
  theme?: SectionThemes;
}

function Section({
  children,
  sectionClassName,
  containerClassName,
  rowClassName,
  fullWidth = false,
  theme = "light",
}: SectionProps): JSX.Element {
  return (
    <div
      className={classnames("section", sectionClassName, {
        [style.sectionLight]: theme === "light",
      })}
    >
      <div
        className={classnames(containerClassName, {
          container: fullWidth === false,
          "container-fluid": fullWidth === true,
        })}
      >
        <div className={classnames("row", rowClassName)}>{children}</div>
      </div>
    </div>
  );
}

export default Section;
