import React from "react";
import classnames from "classnames";

import logo from "../../../assets/images/logo-white.svg";

import style from "./Footer.module.scss";

function Footer(): JSX.Element {
  return (
    <footer className={classnames("d-flex flex-column justify-content-center", style.footer)}>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="d-flex flex-column flex-md-row justify-content-between">
              <div className="d-flex flex-row flex-md-column justify-content-center">
                <img src={logo} alt="Stream AI" />
              </div>
              <div className="d-flex flex-column justify-content-center mt-3 mt-md-0">
                <div className="d-flex flex-md-row flex-column justify-content-center">
                  <a href="mailto:kontakt@streamai.pl">kontakt@streamai.pl</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
